import type {FC} from 'react';
import {Toaster} from '../../components/toaster';
import {HelmetProvider} from 'react-helmet-async';
import {Outlet} from "react-router-dom";
import {NProgress} from '../../components/nprogress';

export const Layout: FC = () => {

    return (
        <>
            <HelmetProvider>
                <Toaster/>
                <NProgress/>
                <Outlet/>
            </HelmetProvider>
        </>
    );
};
