import React from 'react';
import {useRoutes} from 'react-router-dom';
import {ThemeProviderWrapper} from "./theme/ThemeProvider";
import {CssBaseline} from "@mui/material";
import AppRoutes from './app/AppRoutes';
import {useAuth} from "./hooks/use-auth";
import AppInit from "./components/AppInit";

function App() {
    const content = useRoutes(AppRoutes);

    const auth = useAuth();

    return (
        <ThemeProviderWrapper>
            <CssBaseline/>
            {auth.isInitialized ? content : <AppInit/>}
        </ThemeProviderWrapper>
    );
}

export default App;
