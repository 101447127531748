import {FC, ReactNode, useState} from 'react';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import AuthApi from "../../api/AuthApi";
import SessionService from "../../services/SessionService";
import {AuthContext} from './authContext';
import {authProviderSlice, isAuthenticatedSelector, isInitializedSelector, userSelector} from "./authProviderSlice";
import {useDispatch, useSelector} from "../../app/store";

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const {children} = props;
    const dispatch = useDispatch();
    const isInitialized = useSelector(isInitializedSelector);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const user = useSelector(userSelector);

    const initialize = async (): Promise<void> => {
        if(SessionService.getSessionGuid) {
            try {
                const accessToken = SessionService.getSessionGuid();

                if (accessToken) {
                    const serverRequest = await AuthApi.getCurrentUser();

                    if (serverRequest.data.isSuccess) {
                        dispatch(authProviderSlice.actions.initialize({
                            isAuthenticated: true,
                            user: serverRequest.data.data,
                        }));
                    } else {
                        dispatch(authProviderSlice.actions.initialize({
                            isAuthenticated: false,
                            user: null,
                        }));
                    }

                } else {
                    dispatch(authProviderSlice.actions.initialize({
                        isAuthenticated: false,
                        user: null,
                    }));
                }
            } catch (err) {
                console.error(err);
                dispatch(authProviderSlice.actions.initialize({
                    isAuthenticated: false,
                    user: null,
                }));
            }

        }
        else {
            dispatch(authProviderSlice.actions.initialize({
                isAuthenticated: false,
                user: null,
            }));
        }
    };
    
    useEffect(() => {
        initialize();
    }, [dispatch]);

    return (
        <AuthContext.Provider
            value={{
                isInitialized: isInitialized,
                isAuthenticated: isAuthenticated,
                user: user,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};