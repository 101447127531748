import axios, {AxiosPromise} from "axios";
import {ILoginResponse} from "../apiModels/response/ILoginResponse";
import {IApiResult} from "../apiModels/common/IApiResult";
import {ILoginRequest} from "../apiModels/request/ILoginRequest";
import SessionService from "../services/SessionService";

class AuthApi {
    refreshSession(): AxiosPromise<boolean> {
        return axios({
            method: 'post',
            url: 'api/auth/refreshSession',
            withCredentials: true
        });
    }

    getCurrentUser(): AxiosPromise<IApiResult<ILoginResponse>> {
        return axios({
            method: 'post',
            url: 'api/auth/getCurrentUser',
            headers:{ Authorization: `Bearer ${SessionService.getSessionGuid()}`}
        })
    }

    login(loginDto: ILoginRequest): AxiosPromise<IApiResult<ILoginResponse>> {
        return axios({
            method: 'post',
            url: 'api/auth/login',
            data: loginDto
        })
    }

    logout(): AxiosPromise {
        return axios({
            method: 'post',
            url: 'api/auth/logout',
            withCredentials: true
        })
    }

}

const api = new AuthApi();

export default api as AuthApi;
