import type {FC} from 'react';
import PropTypes from 'prop-types';
import {Helmet, HelmetProvider} from 'react-helmet-async';

interface SeoProps {
    title?: string;
}

export const Seo: FC<SeoProps> = (props) => {
    const {title} = props;

    const fullTitle = title ? title + ' | Project ATLAS' : 'Project ATLAS';

    return (
        <Helmet>
            <title>{fullTitle}</title>
        </Helmet>
    );
};

Seo.propTypes = {
    title: PropTypes.string,
};
