import {LocaleEnum} from "../apiModels/common/LocaleEnum";
import i18n from "../app/i18n";


class LocaleService {

    getCurrentUserLocale(): LocaleEnum {
        const language = i18n.languages[0];
        switch (language) {
            case "sp":
                return LocaleEnum.Spanish 
            default:
                return LocaleEnum.English
        }
        
    }

    getCurrentUserLocaleString(): string {
        return i18n.languages[0];
    }

}

const api = new LocaleService();

export default api as LocaleService;
