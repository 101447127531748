import React, {useEffect} from 'react';
import {
    accountActivationStateSelector,
    activateAccountAsync,
    activationErrorMessageSelector
} from "./accountActivationSlice";
import {Link as RouterLink, useParams} from "react-router-dom";
import NavigationService from '../../services/NavigationService';
import SessionService from '../../services/SessionService';
import {useSelector, useDispatch} from '../../app/store';
import {Seo} from "../../components/seo";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {paths} from "../../paths";
import SvgIcon from "@mui/material/SvgIcon";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";


type RouteParams = {
    hash: string;
}

export default function AccountActivation() {
    const accountActivationState = useSelector(accountActivationStateSelector);

    const activationErrorMessage = useSelector(activationErrorMessageSelector);
    const dispatch = useDispatch();
    let {hash} = useParams<RouteParams>()

    useEffect(() => {
        dispatch(activateAccountAsync(hash));

    }, [dispatch, hash]); // eslint-disable-line react-hooks/exhaustive-deps

    if (SessionService.isUserLoggedIn()) {
        NavigationService.go("/")
    }

    return (
        <>
            <Seo title="Account Activation"/>
            <div>
                <Box sx={{mb: 4}}>
                    <Link
                        color="text.primary"
                        component={RouterLink}
                        to={paths.index}
                        sx={{
                            alignItems: 'center',
                            display: 'inline-flex',
                        }}
                        underline="hover"
                    >
                        <SvgIcon sx={{mr: 1}}>
                            <ArrowLeftIcon/>
                        </SvgIcon>
                        <Typography variant="subtitle2">Home</Typography>
                    </Link>
                </Box>
                <Card elevation={16}>
                    <CardHeader
                        title="Account Activation"
                    />

                    <CardContent>
                        <Stack spacing={3}>
                            {
                                accountActivationState.isLoading &&
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    spacing={2}
                                >

                                    <Grid item>
                                        <CircularProgress color="inherit"/>
                                    </Grid>

                                    <Grid item>
                                        <Typography variant={"h5"} align={"center"}>
                                            <strong>Account activation in progress...</strong>
                                        </Typography>

                                    </Grid>
                                </Grid>


                            }
                            {
                                !accountActivationState.isLoading && !accountActivationState.isActivationSuccessful &&
                                <Typography variant={"h5"} align={"center"} color={"error"}>
                                    <strong>Account activation failed</strong> {activationErrorMessage}
                                    <br/>
                                    <br/>
                                    <br/>
                                </Typography>
                            }
                            {
                                !accountActivationState.isLoading && accountActivationState.isActivationSuccessful &&
                                <Typography variant={"h5"} align={"center"}>
                                    <strong>Account activation finished successfully</strong>
                                    You can login to your account.
                                    <br/>
                                    <br/>
                                    <br/>
                                </Typography>
                            }
                        </Stack>
                        <Link
                            component={RouterLink}
                            to={paths.auth.login.index}
                            underline="hover"
                            variant="subtitle2"
                        >
                            Go to login form
                        </Link>
                    </CardContent>
                </Card>
            </div>
        </>
        /* <Container component="main" maxWidth="xs">
             <div className={classes.paper}>
                 <Backdrop className={classes.backdrop} open={accountActivationState.isLoading}>
                     <CircularProgress color="inherit"/>
                 </Backdrop>
                 <Avatar className={classes.avatar}>
                     <PersonAddIcon/>
                 </Avatar>
                 <Typography component="h1" variant="h5">
                     {t("account_activation.title")}
                 </Typography>
 
                 {
                     accountActivationState.isLoading === false && accountActivationState.isActivationSuccessful === false &&
                     <Typography variant={"h5"} align={"center"} color={"error"}>
                         <strong> {t("account_activation.failed")}</strong> {activationErrorMessage}
                         <br/>
                         <br/>
                         <br/>
                     </Typography>
                 }
                 {
                     accountActivationState.isLoading === false && accountActivationState.isActivationSuccessful &&
                     <Typography variant={"h5"} align={"center"}>
                         <strong>{t("account_activation.success")}</strong> {t("account_activation.login")}
                         <br/>
                         <br/>
                         <br/>
                     </Typography>
                 }
 
 
                 <Grid container={true} spacing={5} justify="center">
                     <BoboLinkButton to={`/login`} color={"secondary"}>
                         {t("user.login")} <Icons.LoginIcon/>
                     </BoboLinkButton>
                 </Grid>
             </div>
         </Container>*/
    );
}