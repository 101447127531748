import React, {PropsWithChildren} from 'react';
import {StyledEngineProvider, Theme, ThemeProvider} from '@mui/material';
import {createTheme} from './base';

export function ThemeProviderWrapper(props: PropsWithChildren) {
    const theme: Theme = createTheme({
        colorPreset:"blue",
        contrast: "high",
        direction: "ltr",
        paletteMode: "light",
        responsiveFontSizes: true
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {props.children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
