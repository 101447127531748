export const paths = {
  index: '/',
  docs:'/documentation',
  dashboard: {
    index: '/dashboard',
    devices: {
      index: '/dashboard/devices',
      deviceDetails: '/dashboard/device/:devicesId',
    },
    counter: '/dashboard/counter',
  },
  auth: {
    index: '/auth',
    forgotPassword:'/auth/forgot-password',
    login: {
      index:'/auth/login',
      withReturnTo:'/auth/login',
    },
    register:  '/auth/register',
    resetPassword:'/auth/reset-password',
    verifyCode: '/auth/verify-code',
    accountActivation: '/auth/account-activation/:hash',
  },
  
};
