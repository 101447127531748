import axios, {AxiosPromise} from "axios";
import {ILoginResponse} from "../apiModels/response/ILoginResponse";
import {IApiResult} from "../apiModels/common/IApiResult";
import {ILoginRequest} from "../apiModels/request/ILoginRequest";
import SessionService from "../services/SessionService";
import {IUserDeviceResponse} from "../apiModels/response/IUserDeviceResponse";

class StationsApi {

    getAllForUser(): AxiosPromise<IApiResult<IUserDeviceResponse[]>> {
        return axios({
            method: 'post',
            url: 'api/UserDevice/GetAllForUser',
            headers:{ Authorization: `Bearer ${SessionService.getSessionGuid()}`}
        })
    }
}

const api = new StationsApi();

export default api as StationsApi;
