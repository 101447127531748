import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {IApiResult} from "../../apiModels/common/IApiResult";
import {IFieldUpdateDto} from "../../apiModels/IFieldUpdateDto";
import SessionService from "../../services/SessionService";
import AuthApi from "../../api/AuthApi";
import {AxiosResponse} from "axios";

import {ILoginResponse} from "../../apiModels/response/ILoginResponse";
import {ILoginRequest} from "../../apiModels/request/ILoginRequest";
import {LocaleEnum} from "../../apiModels/common/LocaleEnum";
import NavigationService from "../../services/NavigationService";
import LocaleService from "../../services/LocaleService";
import NotificationService from "../../services/NotificationService";
import {paths} from "../../paths";
import {authProviderSlice} from "../../contexts/auth/authProviderSlice";


interface LoginState {
    isFormValid: boolean;
    isLoading: boolean;
    loginRequest: ILoginRequest,
}

const initialState: LoginState = {
    loginRequest: {
        email: "",
        isRemember: false,
        password: "",
        locale: LocaleEnum.English
    },
    isLoading: false,
    isFormValid: true,
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        updateFieldValue: (state, action: PayloadAction<IFieldUpdateDto>) => {
            state.loginRequest = {
                ...state.loginRequest,
                [action.payload.field]: action.payload.value
            };

        },

        loading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },

        updateRememberCheckBox: (state, action: PayloadAction<boolean>) => {
            state.loginRequest = {
                ...state.loginRequest,
                isRemember: action.payload
            };
        },
    },
});

export const goToLoginPage = (): AppThunk => async (dispatch) => {
    NavigationService.go("/login");
};


export const loginAsync = (email: string, password: string): AppThunk => async (dispatch, getState) => {
    dispatch(loginSlice.actions.loading(true));

    try {
        let serverRequest: AxiosResponse<IApiResult<ILoginResponse>>;

        serverRequest = await AuthApi.login({
            email: email,
            isRemember: false,
            password: password,
            locale: LocaleService.getCurrentUserLocale()
        });

        if (serverRequest.data.isSuccess) {
            SessionService.setSessionGuid(serverRequest.data.data.sessionToken, serverRequest.data.data.expirationDate);

            dispatch(authProviderSlice.actions.initialize({
                isAuthenticated: true,
                user: serverRequest.data.data
            }));

            NavigationService.go(paths.dashboard.index);
        } else {
            NotificationService.onRequestFailed(serverRequest.data, 5000)
        }
    } catch (e) {
        NotificationService.onPromiseRejected(e);
    }
    dispatch(loginSlice.actions.loading(false));
};

export const loginStateSelector = (state: RootState) => state.loginState;

export default loginSlice.reducer;
