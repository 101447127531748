import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import NotificationService from "../../services/NotificationService";
import {IUserDeviceResponse} from "../../apiModels/response/IUserDeviceResponse";
import StationsApi from "../../api/StationsApi";

interface DevicesState {
    isLoading: boolean;
    stationsResponse: IUserDeviceResponse[]
}

const initialState: DevicesState = {
    stationsResponse: [],
    isLoading: false,
};

export const stationsSlice = createSlice({
    name: 'stations',
    initialState,
    reducers: {
        stationsLoaded: (state, action: PayloadAction<IUserDeviceResponse[]>) => {
            state.stationsResponse = action.payload
        },

        loading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
});



export const loadStationsAsync = (): AppThunk => async (dispatch, getState) => {
    dispatch(stationsSlice.actions.loading(true));
    try {
        const serverRequest = await StationsApi.getAllForUser();

        if (serverRequest.data.isSuccess) {
            dispatch(stationsSlice.actions.stationsLoaded(serverRequest.data.data));
        } else {
            NotificationService.onRequestFailed(serverRequest.data)
        }
    } catch (e) {
        NotificationService.onPromiseRejected(e);
    }
    dispatch(stationsSlice.actions.loading(false));
};

export const stationsSelector = (state: RootState) => state.stationsState.stationsResponse;

export default stationsSlice.reducer;