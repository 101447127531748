import toast from 'react-hot-toast';
import SessionService from "./SessionService";
import {IApiResultBase} from "../apiModels/common/IApiResult";
import i18n from '../app/i18n';
import {ErrorMessagesEnum} from "../apiModels/common/ErrorMessagesEnum";

class NotificationService {

    onPromiseRejected(error: any) {
        if (NotificationService.tryHandlePromiseRejectedError(error)) {
            return;
        }

        console.log("Error:" + error);
        
        toast.error(i18n.t('server_errors.app_error'), );
    }

    static tryHandlePromiseRejectedError(error: any) {
        if (error.response && error.response.status === 401) {
            SessionService.redirectToLogin();
            return true;
        }
        
        return false;
    }

    onRequestFailed(response: IApiResultBase, autoClose: false | number = false) {
        const errorId = ErrorMessagesEnum[response.errorMessagesEnum];
        toast.error(i18n.t(`server_errors.${errorId}`));
    }

    onSuccess(messageId: string) {
        toast.success(i18n.t(messageId));
    }

    onError(messageId: string) {
        toast.error(i18n.t(messageId));
    }
    onErrorMessage(message: string) {
        toast.error(message);
    }

    onSuccessMessage(message: string) {
        toast.success(message, );
    }
}

const api = new NotificationService();
export default api as NotificationService;


