import axios, {AxiosPromise} from "axios";
import {IApiResult, IApiResultBase} from "../apiModels/common/IApiResult";
import SessionService from "../services/SessionService";
import {IUserSettingsRequest} from "../apiModels/request/UserSettingsRequest";
import {IRestoreAccessRequest} from "../apiModels/request/IRestoreAccessRequest";
import {IPasswordResetRequest} from "../apiModels/request/IPasswordResetRequest";
import {IRegisterRequest} from "../apiModels/request/IRegisterRequest";
import {IUserProfileResponse} from "../apiModels/response/IUserProfileResponse";
import {IHashRequest} from "../apiModels/request/IHashRequest";

class UsersApi {

    register(registerRequest: IRegisterRequest): AxiosPromise<IApiResultBase> {
        return axios({
            method: 'post',
            url: 'api/users/register',
            data: registerRequest
        })
    };

    restoreAccess(restoreAccessRequest: IRestoreAccessRequest): AxiosPromise<IApiResultBase> {
        return axios({
            method: 'post',
            url: 'api/users/restoreAccess',
            data: restoreAccessRequest
        })
    };

    resetPassword(passwordResetRequest: IPasswordResetRequest): AxiosPromise<IApiResultBase> {
        return axios({
            method: 'post',
            url: 'api/users/resetPassword',
            data: passwordResetRequest
        })
    };

    checkRecoveryCode(recoveryCode: string): AxiosPromise<IApiResultBase> {
        return axios({
            method: 'post',
            url: 'api/users/checkRecoveryCode',
            data: new IHashRequest(recoveryCode)
        })
    };

    activate(hash: string): AxiosPromise<IApiResultBase> {
        return axios({
            method: 'post',
            url: 'api/users/activate',
            withCredentials: true,
            data: new IHashRequest(hash)
        })
    }

    getUserProfile(): AxiosPromise<IApiResult<IUserProfileResponse>> {
        return axios({
            method: 'get',
            url: 'api/user/getProfile/',
            headers: {Authorization: `Bearer ${SessionService.getSessionGuid()}`}
        },)
    }

    saveUserProfile(userProfileRequest: IUserSettingsRequest, onUploadProgressFunction: Function): AxiosPromise<IApiResultBase> {
        return axios({
            method: 'post',
            url: 'api/user/saveProfile',
            data: userProfileRequest,
            headers: {Authorization: `Bearer ${SessionService.getSessionGuid()}`},
            onUploadProgress: function (progressEvent) {
                // @ts-ignore
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onUploadProgressFunction(percentCompleted)
            }
        })
    };
}

const api = new UsersApi();

export default api as UsersApi;