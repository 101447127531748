import {FC, ReactNode} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {paths} from "../paths";
import {Navigate, useLocation} from "react-router-dom";
import SessionService from "../services/SessionService";
import {isAuthenticatedSelector} from "../contexts/auth/authProviderSlice";
import {useSelector} from "react-redux";

interface AuthGuardProps {
    children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
    const {children} = props;
    const location = useLocation();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

    if (!isAuthenticated) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname);
        }
        return <Navigate to={paths.auth.login.index} replace={true}/>;
    }

    if (isAuthenticated && !SessionService.getSessionGuid()) {
        return <Navigate to={paths.auth.login.index} replace={true}/>;
    }

    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation}/>;
    }

    return <>{children}</>;
};

AuthGuard.propTypes = {
    children: PropTypes.node,
};
