import React, {useEffect, useState} from 'react';
import {HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Seo} from "../../components/seo";


export function MqttLogs() {

    const [messages, setMessages] = useState<string[]>([]);

    const [isConnected, setIsConnected] = useState<boolean>(false);

    useEffect(() => {
        setIsConnected(true);
        if (!isConnected) {

            let hubConnection: HubConnection = new HubConnectionBuilder()
                .withUrl('/hubs/agenthub', {  // localhost from **AspNetCore3.1 service**
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets
                })

                .configureLogging(LogLevel.Information)
                .build();

            hubConnection.on("Broadcast", (topic, payload) => {
                addNewMessage(`topic: ${topic}, payload: ${payload}`);
            });

            hubConnection.on("AgentConnectionStatus", (isConnected) => {
                let status = isConnected ? "Connected" : "Disconnected"

                addNewMessage(status);
            })

            hubConnection
                .start()
                .then(() => {
                    addNewMessage("Agent hub connected");
                })
                .catch((err) => {
                    addNewMessage(err.message());
                });


        }


    }, []);

    const addNewMessage = (message: string): void => {
        let currentDate = new Date();
        let newMessage = `${currentDate.toLocaleTimeString()} - ${message}`;

        console.log(newMessage);
        setMessages(messages => [newMessage, ...messages]);
        setMessages(messages => ["-------------", ...messages]);
    }

    return (
        <>
            <Seo title="MQTT Logs"/>
            <div>
                <Card sx={{minWidth: 275, margin: 2}}>
                    <CardContent>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>

                        </Typography>
                        <Typography variant="h5" component="div">
                            Messages
                        </Typography>
                        {
                            messages && messages.map((item, index) =>
                                <Typography key={index}>{item}</Typography>
                            )
                        }
                    </CardContent>
                    <CardActions>
                        <Button size="small">Clean</Button>
                    </CardActions>
                </Card>
            </div>
        </>
    );
}
