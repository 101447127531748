import {useState, MouseEvent, useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';

import {
    Grid,
    Typography,
    Card,
    Box,
    IconButton,
    Avatar,
    Button,
    ToggleButton,
    ToggleButtonGroup,
    Divider,
    styled,
} from '@mui/material';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import {useTranslation} from 'react-i18next';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import {Seo} from "../../components/seo";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import {useDispatch, useSelector} from "../../app/store";
import {loadStationsAsync, stationsSelector} from "./stationsSlice";
import {StationBanner} from "./StationBanner";

const AvatarWrapper = styled(Box)(
    ({theme}) => `
      .MuiAvatar-root {
        height: auto;
        width: ${theme.spacing(26)};
        transition: ${theme.transitions.create(['opacity'])};

        &:hover {
          opacity: .8;
        }
      }
`
);

const IconButtonError = styled(IconButton)(
    ({theme}) => `
       color: ${theme.palette.error.main};
  
       &:hover {
        background: ${theme.palette.error.lightest};
      }
  `
);

export function Stations() {
    const {t}: { t: any } = useTranslation();
    const [toggleView, setToggleView] = useState<string | null>('grid_view');
    const location = useLocation();
    const stations = useSelector(stationsSelector);

    const dispatch = useDispatch();

    const handleViewOrientation = (
        _event: MouseEvent<HTMLElement>,
        newValue: string | null
    ) => {
        setToggleView(newValue);
    };


    useEffect(() => {
        dispatch(loadStationsAsync());
    }, []);

    return (
        <>
            <Seo title="Stations"/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={"xl"}>
                    <Stack
                        spacing={{
                            xs: 3,
                            lg: 4,
                        }}
                    >
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h4">Your Atlas Stations</Typography>
                            </Box>
                        </Grid>


                        {stations.length === 0 ? (
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        py: 10
                                    }}
                                    variant="h3"
                                    fontWeight="normal"
                                    color="text.secondary"
                                    align="center"
                                >
                                    {t(
                                        "We couldn't find any products matching your search criteria"
                                    )}
                                </Typography>
                            </Grid>
                        ) : (
                            <>
                                {stations.map((station) => {
                                    return (
                                        <Grid key={station.userDeviceId} item xs={12}>
                                            <StationBanner station={station}/>
                                        </Grid>
                                    );
                                })}

                            </>
                        )}


                    </Stack>
                </Container>
            </Box>
        </>
    );
};

