import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import loginSlice from "../features/login/loginSlice";
import authProviderSlice from "../contexts/auth/authProviderSlice";

import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import stationsSlice from "../features/stations/stationsSlice";
import accountActivationSlice from "../features/accountActivation/accountActivationSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    loginState: loginSlice,
    authProviderState: authProviderSlice,
    stationsState: stationsSlice,
    accountActivationState: accountActivationSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
