import type { FC } from 'react';
import { useTheme } from '@mui/material/styles';

export const Logo: FC = () => {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;

  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="460 245 360 360"
      >
        <g paintOrder="stroke">
          <g transform="matrix(1.92115 0 0 1.92115 640 407.322) matrix(.93357 0 0 .93357 0 -.001)">
            <linearGradient
                id="SVGID_1_5_211389"
                x1="251.681"
                x2="249.333"
                y1="353.932"
                y2="130.106"
                gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.006" stopColor="#7DD6F6"></stop>
              <stop offset="1" stopColor="#4ADEDE"></stop>
            </linearGradient>
            <path
                fill="url(#SVGID_1_5_211389)"
                d="M253.65 188.86l55.549 127.602-101.446-1.61-2.954-73.722c1.559-8.573 49.778-57.368 48.851-52.27z"
                transform="translate(-250 -249.999)"
            ></path>
          </g>
          <g transform="matrix(1.92115 0 0 1.92115 640 407.322) matrix(.93357 0 0 .93357 24.429 42.44)">
            <linearGradient
                id="SVGID_2_6_211390"
                x1="339.109"
                x2="86.864"
                y1="285.053"
                y2="338.56"
                gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.006" stopColor="#1AA6EC"></stop>
              <stop offset="1" stopColor="#1E2F97"></stop>
            </linearGradient>
            <path
                fill="url(#SVGID_2_6_211390)"
                d="M315.114 339.613h-90.801l-20.37-35.27 21.813-45.482 1.953.933v.011c-7.618 15.877 2.529 35.238 20.088 36.66a29.43 29.43 0 002.203.086c.749 0 1.476-.032 2.203-.087 2.898-.239 5.6-.955 8.042-2.083h36.55l24.874-43.073 1.248 2.17 20.977 36.312c12.784 22.15-3.212 49.823-28.78 49.823z"
                transform="translate(-276.167 -295.46)"
            ></path>
          </g>
          <g transform="matrix(1.92115 0 0 1.92115 640 407.322) matrix(.93357 0 0 .93357 -44.317 23.692)">
            <linearGradient
                id="SVGID_3_7_211391"
                x1="184.156"
                x2="280.978"
                y1="329.094"
                y2="137.574"
                gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.006" stopColor="#1AA6EC"></stop>
              <stop offset="1" stopColor="#1E2F97"></stop>
            </linearGradient>
            <path
                fill="url(#SVGID_3_7_211391)"
                d="M253.451 211.143l-2.246 4.688c-.402-.835-.803-1.682-1.205-2.517-7.434 15.508-14.857 30.983-22.29 46.491l-21.315 44.44 20.424 35.368h-41.933c-25.568 0-41.564-27.673-28.78-49.823l44.147-76.476 1.248-2.17h51.95z"
                transform="translate(-202.53 -275.378)"
            ></path>
          </g>
          <g transform="matrix(1.92115 0 0 1.92115 640 407.322) matrix(.93357 0 0 .93357 10.818 -20.102)">
            <linearGradient
                id="SVGID_4_8_211392"
                x1="225.333"
                x2="339.141"
                y1="161.022"
                y2="388.213"
                gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.006" stopColor="#1AA6EC"></stop>
              <stop offset="1" stopColor="#1E2F97"></stop>
            </linearGradient>
            <path
                fill="url(#SVGID_4_8_211392)"
                d="M322.921 253.478l-24.872 43.07H250c.754 0 1.48-.028 2.207-.082 17.551-1.426 27.7-20.78 20.081-36.662-7.43-15.505-14.859-30.983-22.288-46.488h-49.745l20.97-36.313c12.786-22.154 44.764-22.154 57.55 0l44.146 76.475z"
                transform="translate(-261.588 -228.467)"
            ></path>
          </g>
        </g>
      </svg>
  );
};
