import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from "../../app/store";
import UsersApi from "../../api/UsersApi";
import NotificationService from "../../services/NotificationService";
import {IApiResultBase} from "../../apiModels/common/IApiResult";

interface AccountActivationState {
    isLoading: boolean
    isActivationSuccessful: boolean;
    activationErrorMessage: string;
}

const initialState: AccountActivationState = {
    isLoading: true,
    isActivationSuccessful: false,
    activationErrorMessage: ""
};

export const accountActivationSlice = createSlice({
    name: 'accountActivation',
    initialState,
    reducers: {
        receivedAccountActivation: (state, action: PayloadAction<IApiResultBase>) => {
            state.isLoading = false;
            state.isActivationSuccessful = action.payload.isSuccess;
            state.activationErrorMessage = action.payload.message;
        },

        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    },
});

export const activateAccountAsync = (hash: string | undefined): AppThunk => async (dispatch, getState) => {

    dispatch(accountActivationSlice.actions.setLoading(true));
    try {
        const serverRequest = await UsersApi.activate(hash as string);
        dispatch(accountActivationSlice.actions.receivedAccountActivation(serverRequest.data));
    } catch (e) {
        NotificationService.onPromiseRejected(e);
    }
    dispatch(accountActivationSlice.actions.setLoading(false));
};

export const accountActivationStateSelector = (state: RootState) => state.accountActivationState;
export const activationErrorMessageSelector = (state: RootState) => state.accountActivationState.activationErrorMessage;

export default accountActivationSlice.reducer;
