import React from 'react';
import {HomeHero} from "./sections/home-hero";
import {useMobileNav} from "./sections/use-mobile-nav";
import {TopNav} from "./sections/top-nav";
import {Seo} from "../../components/seo";

export function Landing() {

    const mobileNav = useMobileNav();
   
    return (
        <div>
            <Seo />
            <TopNav onMobileNavOpen={mobileNav.handleOpen} />
            <HomeHero/>
        </div>
    );
}
