import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {AuthProvider} from "./contexts/auth/AuthProvider";

const container = document.getElementById('root')!;
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const root = createRoot(container);

root.render(
    /* <React.StrictMode>*/
    <Provider store={store}>

        <BrowserRouter basename={baseUrl}>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </BrowserRouter>

    </Provider>
    /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
