import {Counter} from "../features/counter/Counter";
import {Layout as DashboardLayout} from "../layouts/dashboard/layout";
import {Layout as AuthLayout} from "../layouts/auth/classic-layout";
import {Layout as RootLayout} from "../layouts/root/";
import {paths} from "../paths";
import {Landing} from "../features/landing/Landing";
import {MqttLogs} from "../features/mqttLogs/MqttLogs";
import {Login} from "../features/login/Login";
import {AuthGuard} from "../guards/auth-guard";
import {RouteObject} from "react-router";
import {GuestGuard} from "../guards/guest-guard";
import {Stations} from "../features/stations/Stations";
import AccountActivation from "../features/accountActivation/AccountActivation";

const AppRoutes: RouteObject[] = [
    {
        path: '',
        element:  <RootLayout/>,
        children: [
            {
                path: paths.index,
                element: <Landing/>
            },
            // DASHBOARD
            {
                path: paths.dashboard.index,
                element: <AuthGuard><DashboardLayout/></AuthGuard>,
                children: [
                    {
                        path: paths.dashboard.index,
                        element: <MqttLogs/>
                    },
                    {
                        path: paths.dashboard.counter,
                        element: <Counter/>
                    },
                    {
                        path: paths.dashboard.devices.index,
                        element: <Stations/>
                    },
                ]
            },

            // AUTH
            {
                path: paths.auth.index,
                element: <GuestGuard><AuthLayout/></GuestGuard>,

                children: [
                    {

                        path: paths.auth.login.withReturnTo,
                        element: <Login/>
                    },
                    {

                        path: paths.auth.accountActivation,
                        element: <AccountActivation/>
                    }

                ]
            },
        ],
    }
];

export default AppRoutes;