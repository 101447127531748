import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { SvgIcon } from '@mui/material';

import File01Icon from '../../icons/untitled-ui/duocolor/file-01';
import HomeSmileIcon from '../../icons/untitled-ui/duocolor/home-smile';
import Devices from '@untitled-ui/icons-react/build/esm/Airpods';

import User03Icon from '@untitled-ui/icons-react/build/esm/LogIn01';

import { paths } from '../../paths';

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: 'Home',
            path: paths.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },

          {
            title: 'Mqtt Log',
            path: paths.dashboard.index,
            icon: (
                <SvgIcon fontSize="small">
                  <HomeSmileIcon />
                </SvgIcon>
            ),
          },

          {
            title: 'Stations',
            path: paths.dashboard.devices.index,
            icon: (
                <SvgIcon fontSize="small">
                  <Devices />
                </SvgIcon>
            ),
          },

          {
            title: 'Login',
            path: paths.auth.login.index,
            icon: (
                <SvgIcon fontSize="small">
                  <User03Icon />
                </SvgIcon>
            ),
          },
        ],
      },
    ];
  },[]);
};
