import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ILoginResponse} from "../../apiModels/response/ILoginResponse";
import {RootState} from "../../app/store";

export interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: ILoginResponse | null;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

type InitializeActionPayload = {
    isAuthenticated: boolean;
    user: ILoginResponse | null;
};

export const authProviderSlice = createSlice({
  name: 'authProvider',
  initialState,
  reducers: {
    initialize: (state, action: PayloadAction<InitializeActionPayload>) => {
      state.isInitialized =true;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    }
  }
});

export const isAuthenticatedSelector = (state: RootState) => state.authProviderState.isAuthenticated;
export const isInitializedSelector = (state: RootState) => state.authProviderState.isInitialized;
export const userSelector = (state: RootState) => state.authProviderState.user;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default authProviderSlice.reducer;
