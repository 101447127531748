import {FC, ReactNode, useEffect} from 'react';
import PropTypes from 'prop-types';
import {paths} from "../paths";
import {Navigate} from "react-router-dom";
import {useDispatch, useSelector} from "../app/store";
import {authProviderSlice, isAuthenticatedSelector} from "../contexts/auth/authProviderSlice";
import SessionService from "../services/SessionService";

/*import {useAuth} from "../hooks/use-auth";*/

interface GuestGuardProps {
    children: ReactNode;
}

export const GuestGuard: FC<GuestGuardProps> = (props) => {
    const {children} = props;
    const isAuthenticated = useSelector(isAuthenticatedSelector);

    const dispatch = useDispatch();

    useEffect(
        () => {
            if (isAuthenticated && !SessionService.getSessionGuid())
            {
                dispatch(authProviderSlice.actions.initialize({
                    isAuthenticated: false,
                    user: null,
                }));
            }
        },
        [dispatch]
    );

    return <>
        {
            isAuthenticated && SessionService.getSessionGuid() &&
            <Navigate to={paths.dashboard.index}/>
        }
        {children}
    </>;
};

GuestGuard.propTypes = {
    children: PropTypes.node,
};
