import type {FC} from 'react';
import Settings04Icon from '@untitled-ui/icons-react/build/esm/Settings04';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import {IUserDeviceResponse} from "../../apiModels/response/IUserDeviceResponse";

type StationBannerProps={
    station: IUserDeviceResponse;
}

export const StationBanner= (props: StationBannerProps) => {

    return (
        <Card>
            <Stack
                alignItems="center"
                direction={{
                    xs: 'column',
                    md: 'row',
                }}
                spacing={4}
                sx={{
                    borderRadius: 2.5,
                    p: 4,
                }}
                {...props}
            >
                <Box
                    sx={{
                        width: 250,
                        '& img': {
                            width: '100%',
                        },
                    }}
                >
                    <img alt="Atlas Station Image" src="/static/images/station_small.png"/>
                </Box>
                <Box sx={{flexGrow: 1}}>
                    <Typography
                        color="text.primary"
                        sx={{mt: 2}}
                        variant="h5"
                    >
                        {props.station.name}
                    </Typography>
                    
                    <Typography
                        color={props.station.isOnline? "green": "error"}
                    >
                        {props.station.isOnline? "online": "offline"}
                    </Typography>

                    <Typography
                        color="text.primary"
                        sx={{mt: 1}}
                        variant="body1"
                    >
                        Location:  {props.station.location}
                    </Typography>
                    
                    <Typography
                        color="text.primary"
                        sx={{mt: 1}}
                        variant="body1"
                    >
                        Next watering: February 22 Wed  
                    </Typography>
                    <Typography
                        color="text.primary"
                        sx={{mt: 1}}
                        variant="body1"
                    >
                        Moisture: {props.station.sensor.Moisture}%
                    </Typography>
                    <Box sx={{mt: 2}}>
                        <Button
                            color="primary"
                            onClick={() => {
                            }}
                            startIcon={
                                <SvgIcon>
                                    <Settings04Icon/>
                                </SvgIcon>
                            }
                            variant="contained"
                        >
                            Open Dashboard
                        </Button>
                    </Box>
                </Box>
            </Stack>
        </Card>
    );
};

